import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0C2C51',
    },
    secondary: {
      main: '#8DA9C4',
    },
    background: {
      default: '#f8faff',
      paper: '#f8faff',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});

export default theme;
