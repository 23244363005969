import * as yup from 'yup';

const signInSchema = yup.object().shape({
  email: yup.string().email('invalid_email').required('invalid_email'),
  password: yup
    .string()
    .min(6, 'password_min_length')
    .required('password_min_length'),
});

export default signInSchema;
