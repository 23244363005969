import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideNotification } from 'store/notifications/notifiactions';
import { Snackbar, Alert } from '@mui/material';
import { t } from 'i18next';

function AppSnackbar() {
  const notification = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();

  return notification.type ? (
    <Snackbar
      open={notification.isOpen}
      onClose={() => dispatch(hideNotification())}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ maxWidth: '35rem' }}
    >
      <Alert severity={notification.type}>{t(notification.message)}</Alert>
    </Snackbar>
  ) : null;
}

export default AppSnackbar;
