import React from 'react';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'components/Link/Link';
import signUpSchema from 'pages/SignUp/utils/signUpFormSchema';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';

type FormData = InferType<typeof signUpSchema>;

function SignUp() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(signUpSchema),
    mode: 'onBlur',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirm_password: '',
    },
  });

  const onSubmit = (data: FormData) => {
    console.log(data);
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6, md: 4 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h3" textAlign="center" color="primary">
              {t('sign_up')}
            </Typography>
          </Box>
          <Box
            component="form"
            display="flex"
            width="100%"
            flexDirection="column"
            gap={2}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('first_name')}
                  variant="outlined"
                  fullWidth
                  error={!!errors.firstname}
                  helperText={getErrorText(errors.firstname)}
                />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('last_name')}
                  variant="outlined"
                  error={!!errors.lastname}
                  helperText={getErrorText(errors.lastname)}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label={t('email')}
                  variant="outlined"
                  error={!!errors.email}
                  helperText={getErrorText(errors.email)}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label={t('password')}
                  variant="outlined"
                  error={!!errors.password}
                  helperText={getErrorText(errors.password)}
                />
              )}
            />
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label={t('confirm_password')}
                  variant="outlined"
                  error={!!errors.confirm_password}
                  helperText={getErrorText(errors.confirm_password)}
                />
              )}
            />
            <Button type="submit" variant="contained" size="large">
              {t('sign_up')}
            </Button>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="body1">
              {t('already_have_an_account')}
            </Typography>
            <Link to="/login" label={t('sign_in')} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignUp;
