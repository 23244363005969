import React from 'react';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'components/Link/Link';
import { getErrorText } from 'helpers/getErrorText';
import { InferType } from 'yup';
import signInSchema from 'pages/SignIn/utils/signInFormSchema';

type FormData = InferType<typeof signInSchema>;

function SignIn() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(signInSchema),
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: FormData) => {
    console.log(data);
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6, md: 4 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h3" textAlign="center" color="primary">
              {t('sign_in')}
            </Typography>
          </Box>
          <Box
            component="form"
            display="flex"
            width="100%"
            flexDirection="column"
            gap={2}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label={t('email')}
                  variant="outlined"
                  error={!!errors.email}
                  helperText={getErrorText(errors.email)}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label={t('password')}
                  variant="outlined"
                  error={!!errors.password}
                  helperText={getErrorText(errors.password)}
                />
              )}
            />
            <Button type="submit" variant="contained" size="large">
              {t('sign_in')}
            </Button>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="body1">{t('dont_have_an_account')}</Typography>
            <Link label={t('sign_up')} to="/register" />
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="body1">{t('forgot_password')}</Typography>
            <Link label={t('password_reset')} to="/password-reset" />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignIn;
