import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export interface OnlyUnauthorizedRouteProps {
  children: ReactNode;
}

function OnlyUnauthorizedRoute({ children }: OnlyUnauthorizedRouteProps) {
  const token = localStorage.getItem('token');

  if (token != null) return <Navigate to="/" replace />;

  return <>{children}</>;
}

export default OnlyUnauthorizedRoute;
