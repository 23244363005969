import React from 'react';
import {
  Typography,
  Link as MuiLink,
  TypographyVariant,
  TypographyOwnProps,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  to: string;
  label: string;
  typographyVariant?: TypographyVariant;
  color?: TypographyOwnProps['color'];
  fontWeight?: TypographyOwnProps['fontWeight'];
}

function Link({
  to,
  label,
  typographyVariant = 'body1',
  color = 'primary',
  fontWeight = 'medium',
}: LinkProps) {
  return (
    <MuiLink component={RouterLink} to={to} relative="path">
      <Typography
        variant={typographyVariant}
        color={color}
        fontWeight={fontWeight}
      >
        {label}
      </Typography>
    </MuiLink>
  );
}

export default Link;
