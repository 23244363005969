import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { t } from 'i18next';

function NotFound() {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h2" color="primary">
        404
      </Typography>
      <Divider orientation="vertical" sx={{ height: '4rem' }} />
      <Typography variant="h2" color="primary">
        {t('page_not_found')}
      </Typography>
    </Box>
  );
}

export default NotFound;
